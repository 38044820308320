import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import Email from './components/Email'
import Address from './components/Address';
// import Mobile from './components/Mobile';
import NavigationButt from './components/NavigationButt';
import Store from './components/Store'
import EmailIcon from './image/sms.svg'
import AddressIcon from './image/global-search.svg'
import MobileIcon from './image/mobile.svg'


import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC8_IO4dYeQuXUSUk9nwTRHbSRVH3B_jR0",
  authDomain: "regmenowweb.firebaseapp.com",
  projectId: "regmenowweb",
  storageBucket: "regmenowweb.firebasestorage.app",
  messagingSenderId: "1025336601516",
  appId: "1:1025336601516:web:33d47ce7e942d7d6ef6809",
  measurementId: "G-7MM1BCY0SN"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App(props) {

  const [email, setEmail] = React.useState('')
  const [selectedTab, setSelectedTab] = React.useState('Email')
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const inputComponents = {
    'Email': <Email email={email} setEmail={setEmail} isMobile={isMobile} />,
    // 'Mobile': <Mobile isMobile={isMobile} />,
    'Address': <Address isMobile={isMobile} />
  }

  return (
    <div>
      <Store />
      <div className={`h-full ${isMobile ? '' : 'flex py-20'}`} >
        <NavigationButt titleName={selectedTab} isMobile={isMobile} email='Email' EmailIcon={EmailIcon} address='Address' AddressIcon={AddressIcon} mobile='Mobile' MobileIcon={MobileIcon} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {inputComponents[selectedTab]}
        <div className='fixed w-full'>
          <ToastContainer position='bottom-center'
            autoClose={1000} />
        </div>
      </div >
    </div>
  )
}

export default App;
